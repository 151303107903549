<template>
  <div class="wrapper">
    <AppHeader />
    <br>
    <Suspense>
      <template #default>
      <Technologys />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "@/components/Spinners/Loading.vue";
const Technologys = defineAsyncComponent(() =>
  import('@/components/Pages/Technologys.vue')
)
export default {
   name: 'Technology',
   components: {
    AppHeader,
    AppFooter,
    Technologys,
    Loading,
    Suspense
  },
}
</script>

<style>

</style>